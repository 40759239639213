



























import {Component, Prop, Vue} from 'vue-property-decorator';
import Voyage from '../../../models/Voyage.model';

@Component({})
export default class VoyageKeyDataComponent extends Vue {
    @Prop({default: null})
    public voyage!: Voyage | null;
}
